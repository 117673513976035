import React from "react";
import "../styles/Services.css";
import { Container, Row, Col } from "reactstrap";

class Services extends React.Component {
  render() {
    return (
      <div className="section our-service grapping" id="services">
        <Container>
          <div className="title-box">
            <h2>
              Our <b>Services</b>
            </h2>
            <p>
              Interact with staff members to provide them with the most chances to develop their professions and improve their quality of life.
              This dedication relates to our role in overseeing industry interactions and making sure the business carries out its obligation to offer growth prospects.
            </p>
          </div>
          <Row>
            <Col xs="12" sm="6" md="6" lg="3">
              <div className="white-bg common-box our-service-box media">
                <i className="icofont-camera-alt media-icon" />
                <h4>Contract Staffing</h4>
                <p>
                  We provide contract-based staffing options that let businesses hire experts for particular tasks or deadlines.
                </p>
              </div>
            </Col>
            <Col xs="12" sm="6" md="6" lg="3">
              <div className="white-bg common-box our-service-box media">
                <i className="icofont-crown media-icon" />
                <h4>Permanent Staffing</h4>
                <p>
                  In order to help organizations identify the best candidate for long-term success, our team focuses in hiring top talent for permanent employment.
                </p>
              </div>
            </Col>
            <Col xs="12" sm="6" md="6" lg="3">
              <div className="white-bg common-box our-service-box media">
                <i className="icofont-pen-alt-3 media-icon" />
                <h4>Temporary Staffing Solutions</h4>
                <p>
                  To satisfy the varying needs of companies and industries, we temporarily supply both skilled and unskilled labor.
                </p>
              </div>
            </Col>
            <Col xs="12" sm="6" md="6" lg="3">
              <div className="white-bg common-box our-service-box media">
                <i className="icofont-chart-growth media-icon" />
                <h4>Training & Development</h4>
                <p>
                  We run training courses to give prospective employees the abilities and credentials that different businesses want.
                </p>
              </div>
            </Col>
            <Col xs="15" sm="6" md="6" lg="3">
              <div className="white-bg common-box our-service-box media">
                <i className="icofont-paper media-icon" />
                <h4>On-Site Management</h4>
                <p>
                  At the client's location, our on-site management service guarantees smooth staffing solution coordination and operation.
                </p>
              </div>
            </Col>
            <Col xs="12" sm="6" md="6" lg="3">
              <div className="white-bg common-box our-service-box media">
                <i className="icofont-laptop-alt media-icon" />
                <h4>Recruitment Process Outsourcing (RPO)</h4>
                <p>
                  From sourcing candidates to onboarding, our RPO services help businesses streamline their hiring procedures.
                </p>
              </div>
            </Col>
            <Col xs="12" sm="6" md="6" lg="3">
              <div className="white-bg common-box our-service-box media">
                <i className="icofont-tick-boxed media-icon" />
                <h4>Workforce Management</h4>
                <p>
                  We support workforce management, including compliance, payroll services, and employee relations.
                </p>
              </div>
            </Col>
            <Col xs="12" sm="6" md="6" lg="3">
              <div className="white-bg common-box our-service-box media">
                <i className="icofont-search-job media-icon" />
                <h4>Executive Search</h4>
                <p>
                  Finding and hiring senior-level executives who can propel an organization's growth and success is our area of expertise.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Services;
