import React from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { Container, Row, Col } from "reactstrap";
import "../styles/Portfolio.css";

const images = [
  {
    src: require("./../images/portfolio-img1.jpg"),
    title: "Industry Partnerships",
    description:
      "We have successfully partnered with leading industries to provide them with a steady flow of skilled manpower.",
  },
  {
    src: require("./../images/portfolio-img2.jpg"),
    title: "Skilled Workforce Supply",
    description:
      "Our portfolio includes the provision of highly skilled workers who meet the specific requirements of different industries.",
  },
  {
    src: require("./../images/portfolio-img3.jpg"),
    title: "Training & Development",
    description: "We have implemented numerous training programs to enhance the skills of our candidates, ensuring they meet industry standards.",
  },
  {
    src: require("./../images/portfolio-img4.jpg"),
    title: "Successful Placements",
    description:
      "We pride ourselves on a track record of successful placements across various sectors, contributing to both client and candidate satisfaction.",
  },
  {
    src: require("./../images/portfolio-img5.jpg"),
    title: "Client-Centric Approach",
    description:
      "Our portfolio is built on a client-centric approach, focusing on delivering tailored staffing solutions to meet unique business needs.",
  },
  {
    src: require("./../images/portfolio-img6.png"),
    title: "Innovative Staffing Solutions",
    description:
      "We have developed and implemented innovative staffing solutions that cater to the evolving needs of modern industries.",
  },
];

class Portfolio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
  render() {
    const { photoIndex, isOpen } = this.state;

    let image_array = images.map((image, key) => {
      return (
        <Col sm="6" md="4" key={key}>
          <div className="portfolio-box">
            <div className="portfolio-img">
              <img
                src={image.src}
                alt=""
                className="img-fluid"
                onClick={() => this.setState({ isOpen: true, photoIndex: key })}
                width="350px"
                height="261px"
              />
            </div>
            <h5 className="mt-3"> {image.title} </h5>
          </div>
        </Col>
      );
    });
    return (
      <div className="section our-portfolio grapping" id="portfolio">
        <Container>
          <div className="title-box">
            <h2>
              Our <b>Portfolio</b>
            </h2>
            <p>
              Sainath Industrial Services is dedicated to offering outstanding chances for pursuing ambitious
              goals and specializes in meeting the crucial needs of companies.
            </p>
          </div>
          <div>
            <Row>{image_array}</Row>

            {isOpen && (
              <Lightbox
                mainSrc={images[photoIndex]["src"]}
                nextSrc={images[(photoIndex + 1) % images.length]["src"]}
                prevSrc={
                  images[(photoIndex + images.length - 1) % images.length][
                    "src"
                  ]
                }
                onCloseRequest={() => this.setState({ isOpen: false })}
                onMovePrevRequest={() =>
                  this.setState({
                    photoIndex:
                      (photoIndex + images.length - 1) % images.length,
                  })
                }
                onMoveNextRequest={() =>
                  this.setState({
                    photoIndex: (photoIndex + 1) % images.length,
                  })
                }
              />
            )}
          </div>
        </Container>
      </div>
    );
  }
}

export default Portfolio;
