import React from "react";
import { Row, Col, Container } from "reactstrap";
import "../styles/AboutSlider.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class AboutSlider extends React.Component {
  render() {
    return (
      <div className="section about-box grapping bg-parallax" id="about">
        <Container>
          <div className="title-box">
            <h2>About</h2>
            <p>
              Leading recruiting and consulting company Sainath Industrial Services is
              committed to establishing a connection between prosperous enterprises and qualified workers.
              With decades of expertise, we are experts in offering career development, talent acquisition,
              and comprehensive staffing solutions that are specifically designed to satisfy the demands of factories and industries.
            </p>
          </div>

          <Row>
            <Col xs="12" sm="12" md="12" lg="6">
              <Row className="gap-4">
                <Col xs="12" sm="12" md="12" lg="12">
                  <div className="white-bg wel-box common-box media">
                    <h4>Why Choose Us</h4>
                    <p>
                      Sainath Industrial Services is committed to providing excellent, customer-focused services
                      with a focus on moral behavior. In order to satisfy the changing needs of the industry,
                      we support skill development programs in addition to our expertise in flexible staffing solutions,
                      such as temporary employment and headcount outsourcing.
                    </p>
                  </div>
                </Col>
                <Col xs="12" sm="12" md="12" lg="12">
                  <div className="white-bg wel-box common-box media">
                    <h4>Our Vision</h4>
                    <p>
                      Our goal is to become the area's top staffing company, renowned for our moral business conduct,
                      dedication to excellence, and creative problem-solving. In addition to enabling people to fulfill their
                      personal and professional goals, we seek to develop a sustainable workforce that can adapt to the ever-changing demands of many industries.
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs="12" sm="12" md="12" lg="6">
              <Row className="gap-4">
                <Col xs="12" sm="12" md="12">
                  <div className="white-bg wel-box common-box media">
                    <p>
                      In 2023, Sainath Industrial Services was established with the goal of supporting skilled labor
                      industries and offering worthwhile career prospects. We work to close the gap between companies
                      and job seekers because we recognize how important it is to connect the right person with the right position.
                      We stand out in the employment market thanks to our dedication to quality, moral business conduct, and client-centered methodology.
                    </p>
                  </div>
                </Col>
                <Col xs="12" sm="12" md="12">
                  <div className="white-bg wel-box common-box media">
                    <h4>Our Mission</h4>
                    <p>
                      By establishing a link between the skilled industry and the educated youth, we want to lower unemployment.
                      Through skill development and chances for both industrial employment and self-employment, we hope to empower people.
                      We assist our applicants in reaching their professional objectives by encouraging commitment and discipline.
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default AboutSlider;
