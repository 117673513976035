import React from "react";
import "../styles/About.css";
import logoB from "../logoBlack.svg";
import { Container, Row, Col } from "reactstrap";

class About extends React.Component {
  render() {
    return (
      <div className="about welcome-box grapping">
        <Container>
          <div className="title-box">
            <h2>
              <span>Welcome to </span><img src={logoB} className="logo-black" alt="logo" />
            </h2>
            <p>
              Finding talented workers employment options that fit their interests and talents is the mission of Sainath Industrial Services.
            </p>
          </div>
          <Row>
            <Col xs="12" sm="12" md="4">
              <div className="white-bg wel-box common-box media">
                <i className="icofont-live-support media-icon" />
                <h4>Comprehensive Staffing and HR Solutions</h4>
                <p>
                  To satisfy the various demands of various businesses, Sainath Industrial Services provides a
                  broad range of staffing and HR management solutions. Our offerings, which are all intended
                  to improve productivity and streamline processes, include payroll administration, HR consulting,
                  compliance management, staffing, and training and development.
                </p>
              </div>
            </Col>
            <Col xs="12" sm="12" md="4">
              <div className="white-bg wel-box common-box media">
                <i className="icofont-code media-icon" />
                <h4>Technology-Driven and Client-Centric Approach</h4>
                <p>
                  Our technologically advanced solutions allow businesses to maximize productivity and profitability
                  by integrating smoothly with their clients' current systems. We take pride in being the go-to employment
                  company for a number of sectors, such as manufacturing, logistics, healthcare, and more.
                </p>
              </div>
            </Col>
            <Col xs="12" sm="12" md="4">
              <div className="white-bg wel-box common-box media">
                <i className="icofont-drag2 media-icon" />
                <h4>Commitment to Quality and Ethical Standards</h4>
                <p>
                  Sainath Industrial Services is committed to providing excellent, customer-focused services with
                  a focus on moral behavior. In order to satisfy the changing needs of the industry, we support
                  skill development programs in addition to our expertise in flexible staffing solutions, such as
                  temporary employment and headcount outsourcing.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default About;
