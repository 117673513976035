import React from "react";
import { Col, Container, Row } from "reactstrap";
import "../styles/OurBranch.css";

class OurBranch extends React.Component {
  render() {
    return (
      <div className="our-branch grapping">
        <Container>
          <Row className="our-branch-info">
            <Col md="3">
              <div className="logo-2">
                <img src="../logo/logoWhite.svg" alt="logo" />
              </div>
            </Col>
            <Col md="3">
              <h4>Our Branches</h4>
              <ul>
                <li>Pune</li>
                <li>Ahmednagar</li>
              </ul>
            </Col>
            <Col md="3">
              <h4>Contact Us</h4>
              <p>
                <strong>Address:</strong> Ground Floor, M.No-100, Malwani, Bhalawani, Ahmednagar, MH 414103

              </p>
              <p>
                <strong>Mobile:</strong> +918956334392
              </p>
              <p>
                <strong>Email:</strong> <a href="mailto:info@sainathindustrialservices.com">info@sainathindustrialservices.com</a>
              </p>
            </Col>
            <Col md="3">
              <h4>Location</h4>
              <iframe
                title="company-location"
                src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d283.5297649872306!2d72.81444648184859!3d19.17691288766563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sGround%20Floor%2C%20M.No-100%2C%20Malwani%2C%20Bhalawani%2C%20Ahmednagar%2C%20MH%20414103!5e1!3m2!1sen!2sin!4v1729760798356!5m2!1sen!2sin"
                width="100%"
                height="200"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default OurBranch;
